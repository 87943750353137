import React from "react"

interface LogoProps {
  width?: string,
  height?: string,
  customClasses?: string
}

const Logo = ({ width = "w-6", height = "h-6", customClasses }: LogoProps) => {
  return (
    <svg
      viewBox="0 0 780 780"
      className={`${width} ${height} ${customClasses}`}
    >
      <g
        transform="translate(85.000000, 134.443000)"
        fill="#202E43"
        fillRule="nonzero"
        stroke="#202E43"
        strokeOpacity="0"
      >
        <path
          d="M350.2,519.557017 C349.008,519.557017 347.823,519.540667 346.645,519.508 L346.645,519.557017 L346.545,519.505 C316.694333,518.658333 290.791333,507.366667 268.836,485.63 C245.982667,463.012 234.558,435.778667 234.562,403.93 L234.562,308.079 L231.007,288.979 L115.376,288.979 C83.9926667,288.979 56.9886667,277.554333 34.364,254.705 C11.7393333,231.855667 0.430333333,204.740333 0.436997054,173.359 L0.436997054,115.884 C0.436997054,84.4906667 11.746,57.3706667 34.364,34.524 C56.982,11.6773333 83.986,0.25 115.376,0.25 L346.644,0.25 L346.644,0.297 C347.822,0.265666667 349.007333,0.25 350.2,0.25 L465.837,0.25 C538.303667,0.25 576.615667,38.7946667 580.773,115.884 L523.298,115.884 C518.680667,77.104 499.525667,57.7176667 465.833,57.725 L407.664,57.725 C391.972,57.725 378.47,63.3776667 367.158,74.683 C355.846,85.9883333 350.193333,99.7216667 350.2,115.883 L350.2,403.933 C350.2,420.084333 355.852667,433.814667 367.158,445.124 C378.463333,456.433333 391.965333,462.089667 407.664,462.093 C444.597333,460.243667 463.987,440.857333 465.833,403.934 L465.833,288.983 L407.664,288.983 L407.664,230.824 L580.773,230.824 L580.773,403.933 C580.773,442.702333 568.539667,471.669 544.073,490.833 C519.606333,509.997 493.527667,519.571667 465.837,519.557017 L350.2,519.557017 Z M132.345,74.683 C121.035667,85.9923333 115.379333,99.7256667 115.376,115.883 L115.376,173.358 C115.376,189.05 121.032333,202.552 132.345,213.864 C143.657667,225.176 157.391,230.828667 173.545,230.822006 L231.01,230.822006 L231.01,57.725 L173.546,57.725 C157.384,57.725 143.650333,63.3776667 132.345,74.683 Z"
          id="Union_1"
        ></path>
      </g>
    </svg>
  )
}

export default Logo
